import React from "react";
import { ScenarioImage} from "../../interfaces/interfaces";
import {Grid} from "@mui/material";


interface ScenarioImageProps {
    images:ScenarioImage[];
    imageSize?: number;
}
const ScenarioImageGridDisplay: React.FC<ScenarioImageProps> = ({ images, imageSize }) => {


    return (
        <>
            {images && images.length > 0 &&
                images.map((image, index) => (
                    <>
                        <Grid item xs={12}>
                            <img src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${image.location}`}
                                 alt="image" style={{
                                width: imageSize ? `${imageSize}px` : '500px',
                                height: imageSize ? `${imageSize}px` : '500px'
                            }}/> </Grid>
                    </>
                ))
            }
        </>
    );
};

export default ScenarioImageGridDisplay;