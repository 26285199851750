import React, { useState, useRef } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import { VolumeUp, Stop } from '@mui/icons-material';

interface TextToSpeechComponentProps {
    text: string;
}

const TextToSpeechComponent: React.FC<TextToSpeechComponentProps> = ({ text }) => {
    const [loading, setLoading] = useState(false);
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const handleClick = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const endpoint = `${process.env.REACT_APP_REST_API_URL}/api/files/transform/text-to-audio/${token}`;
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text }),
            });
            if (response.ok) {
               // console.log('Audio response ok');
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                // console.log('Audio URL:', url);
                setAudioSrc(url);
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.setAttribute('src', url);
                    audioRef.current.load();
                    audioRef.current.play().then(() => {
                        setIsPlaying(true);
                    }).catch(error => {
                        if (error.name === 'AbortError') {
                            console.error('Audio playback aborted:', error);
                        } else {
                            console.error('Audio playback error:', error);
                        }
                    });
                }
            } else {
                console.error('Failed to fetch audio');
            }
        } catch (error) {
            // @ts-ignore
            if (error.name === 'AbortError') {
                console.error('Fetch aborted:', error);
            } else {
                console.error('Error:', error);
            }
        }
        setLoading(false);
    };

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handleAudioError = (event: React.SyntheticEvent<HTMLAudioElement, Event>) => {
        console.error('Audio playback error:', event);
    };

    return (
        <div>
            <IconButton onClick={isPlaying ? handleStop : handleClick} disabled={loading} size={"small"}>
                {loading ? <CircularProgress size={24} /> : (isPlaying ? <Stop /> : <VolumeUp />)}
            </IconButton>
            {audioSrc && <audio autoPlay={true} ref={audioRef} src={audioSrc} onError={handleAudioError} />}
        </div>
    );
};

export default TextToSpeechComponent;