// material-ui

// project import
import React, {useEffect, useState} from "react";
import {AAR, Annotation, Conduct, GameMessage, Scenario} from "interfaces/interfaces";
import {useParams} from "react-router";
import {
    Button, ButtonGroup,
    Dialog, DialogActions, DialogContent,
    DialogTitle, FormControl,
    Grid, Link,
    Stack, Tab, Tabs, TextField,
    Typography
} from "@mui/material";
import MainCard from "../../../components/MainCard";
import {a11yProps, TabPanel} from "../../../hooks/tabPanelHelpers";
import AARComponent from "../../../components/scenario/AARComponent";
import QuickLoaderComponent from "../../../components/cards/QuickLoaderComponent";
import {Link as RouterLink} from "react-router-dom";
import {ArrowBack, PublishOutlined, RefreshOutlined} from "@mui/icons-material";
import PortalListSelect from "../../../components/portal/PortalListSelect";
import PrintPDF from "../../../components/PrintAARPDF";
import ConductTranscriptComponent from "../../../components/tabletop/ConductTranscriptComponent";
import ActionAnalysisComponent from "../../../ActionAnalysisComponent";
import RecommendationAnalysisComponent from "../../../RecommendationAnalysisComponent";
import ConductSummary from "../../../components/tabletop/ConductSummary";
import AARSummaryComponent from "../../../components/tabletop/AARSummaryComponent";

// ==============================|| SAMPLE PAGE ||============================== //

interface Params {
    companyId: string;
    scenarioId: string;
    conductId: string;
}

const DesignerConductViewPage= () => {
    // @ts-ignore
    const { companyId,scenarioId,conductId } = useParams<Params>();
    const [scenario, setScenario] = useState<Scenario>();
    const [conduct, setConduct] = useState<Conduct>();
    const token = localStorage.getItem('token');
    const [openAnnotateDialog, setOpenAnnotateDialog] = useState(false);
    const [annotateTextValue, setAnnotateTextValue] = useState("");
    const [annotationTargetValue, setAnnotationTargetValue] = useState("");
    const [aar, setAAR] = useState<AAR>();
    const [isAARLoading, setIsAARLoading] = useState<boolean>(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [selectedPortalId, setSelectedPortalId] = useState<string>("");
    const [bannerText, setBannerText] = useState<string>("Generating AAR...");
    const [summaryLoading, setSummaryLoading] = useState<boolean>(false);

    const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
    function handlePortalSelect(portalId:string) {
        setSelectedPortalId(portalId);
    }
    const getSimData = async () => {
        try {
            // console.log(id);
            const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/tabletop/scenario/gettabletop/'+token+'/' + companyId+'/'+scenarioId+'/'+conductId);
            const data = await response.json();
            setConduct(data);
        } catch (error) {
            console.error(error);
        }
    };

     const [tabSelectValue, setTabSelectValue] = useState(0);

     const handleTabSelectChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabSelectValue(newValue);
    };
    useEffect(() => {
        const fetchData = async () => {
            getSimData();
            setBannerText("Loading Scenario...")
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL+'/api/scenario/company/getscenario/'+token+'/' +companyId+'/'+scenarioId);
                const data = await response.json();
                setScenario(data);
            } catch (error) {
                console.error(error);
            }
            setBannerText("Loading AAR...")

            try {
                setSummaryLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/aar/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
                const data = await response.json();
                setAAR(data);
                setSummaryLoading(false);
            } catch (error) {
                console.error("Error updating scenario:", error);
            }

        };
        fetchData();
    }, []);

    function handleRegenerateAAR() {
        console.log("Regenerate AAR");
        setIsAARLoading(true);
        fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/conduct/aar/regen/' + token + '/' + companyId +'/'+scenarioId + '/' + conductId)
            .then(response => response.json())
            .then(data => {
                setAAR(data);
                setIsAARLoading(false);
            })
            .catch(error => {
                console.error("Error regenerating aar:", error);
            });
    }

    function handleAnnotate(id: string) {
        setOpenAnnotateDialog(true);
        setAnnotationTargetValue(id);
        setAnnotateTextValue("");
    };

    const handlePublishDialog = () => {
        setOpenPublishDialog(true);
    };

    const closeAnnotateDialog = () => {
        setOpenAnnotateDialog(false);
        setAnnotateTextValue("");
        setAnnotationTargetValue("");
    };

    const closePublishDialog = () => {
        setOpenPublishDialog(false);
    };

    function handleUnpublishAAR() {
        setIsAARLoading(true);
        fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/aar/unpublishaar/' + token + '/' + companyId + '/' + aar?.portalIds[0]+'/'+scenarioId + '/' + conductId)
            .then(response => response.json())
            .then(data => {
                setAAR(data);
                setIsAARLoading(false);
            })
            .catch(error => {
                console.error("Error unpublishing aar:", error);
            });

        function closeUnpublishDialog() {
            setOpenUnpublishDialog(false);
        }

        closeUnpublishDialog();
    }
    const handleGenAAR = async () => {
        try {
            setIsAARLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/genAAR/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
            const data = await response.json();
            setAAR(data);
            setIsAARLoading(false);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    };
    const handleSaveAnnotation = async () => {

        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/tabletop/scenario/tabletop/annotate/${token}/${companyId}/${scenarioId}/${conductId}`;
        try {
            const annotationVal: Annotation = {
                annotationId: "", madeBy: "", simulationId: "",
                text: annotateTextValue,
                gameMessageId: annotationTargetValue,
                companyId: "",
                scenarioId: ""
            };
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(annotationVal),
            });
            const annotation: Annotation = await response.json();
            conduct?.messages?.forEach((msgItem:GameMessage, index) => {
                if (msgItem.id === annotation.gameMessageId) {
                    if (!msgItem.annotations) {
                        msgItem.annotations = [];
                    }
                    msgItem.annotations?.push(annotation);
                }
            });
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
        closeAnnotateDialog();

    };

    async function handlePublishAAR() {
        //console.log("Publish AAR");
        try {
            setBannerText("Publishing AAR...")
            setIsAARLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/publishAAR/' +
                token + '/' + companyId + '/' + scenarioId + '/' + conductId + '/' + selectedPortalId);
            const data = await response.json();
            setAAR(data);
            setIsAARLoading(false);
        } catch (error) {
            console.error("Error publishing aar:", error);
        }
        closePublishDialog();
    }

    async function handleAARUpdate() {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/aar/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    }

    return (
       <>
       <MainCard title={conduct?.name+" Summary (Conducted on "+conduct?.userAudit?.createdDate+")"} secondary={
           <Stack direction="row" justifyContent="flex-end" spacing={1}>
               <ButtonGroup>
                   {aar && !aar.isPublished &&
                       <>
                           <Button
                               variant="outlined"
                               size={"small"}
                               startIcon={<PublishOutlined/>}
                               onClick={handlePublishDialog}
                           >
                               Publish AAR
                           </Button>
                           <Button
                               variant="outlined"
                               size={"small"}
                               startIcon={<RefreshOutlined/>}
                               onClick={handleRegenerateAAR}
                           >
                               Regenerate AAR
                           </Button>
                       </>
                   }
                   { aar && aar.isPublished &&
                       <Button
                           variant="outlined"
                           size={"small"}
                           startIcon={<PublishOutlined/>}
                           onClick={() => setOpenUnpublishDialog(true)}
                       >
                           Unpublish
                       </Button>
                   }
               </ButtonGroup>
               {aar && conduct &&
                   <PrintPDF aar={aar} conduct={conduct} />
               }
               <ButtonGroup>

                   <Link color="primary" component={RouterLink} to={`/company/scenario/view/${companyId}/${scenarioId}`}>
                       <Button
                           variant="outlined"
                           size={"small"}
                           startIcon={<ArrowBack/>}
                       >
                           Scenario
                       </Button>
                   </Link>
                   <Link color="primary" component={RouterLink} to={`/company/viewcompany/${companyId}`}>
                       <Button
                           variant="outlined"
                           size={"small"}
                           startIcon={<ArrowBack/>}
                       >
                           Company
                       </Button>
                   </Link>
               </ButtonGroup>
           </Stack>
       }>
        <Grid container={true} spacing={2}>
            <Grid item xs={12}>
                <Grid container={true} spacing={1}>
                    <Tabs value={tabSelectValue} onChange={handleTabSelectChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                        <Tab
                            label="Summary"
                            {...a11yProps(0)}
                        />
                        <Tab label="Report" {...a11yProps(1)} />
                        <Tab label="Transcript" {...a11yProps(2)} />
                        <Tab label="Analysis" {...a11yProps(3)} />
                        <Tab label="Recommendations" {...a11yProps(4)}/>
                    </Tabs>
                    <Grid item xs={12}>
                        <TabPanel value={tabSelectValue} index={0} >
                            <Grid container rowSpacing={4.5} columnSpacing={3}>
                                <Grid item xs={12}>
                                    {companyId && scenarioId && conductId  && token &&
                                        <AARSummaryComponent
                                            companyId={companyId}
                                            scenarioId={scenarioId}
                                            conductId={conductId}
                                            token={token}
                                            summaryLoading={summaryLoading}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {scenario && conduct &&
                                       <ConductSummary scenario={scenario} conduct={conduct}/>
                                    }
                                </Grid>
                            </Grid>

                        </TabPanel>
                        <TabPanel value={tabSelectValue} index={1} >
                            {isAARLoading &&
                                <QuickLoaderComponent loadBanner={bannerText} />
                            }
                            {aar && conduct ?(
                                <AARComponent scenarioId={scenarioId} conductId={conductId} conduct={conduct}
                                              companyId={companyId} aar={aar} editable={!aar.isPublished}/>): (
                                <Typography variant="h6">No AAR Generated Yet - <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleGenAAR}
                                >
                                    Generate AAR
                                </Button></Typography>
                            )}
                        </TabPanel>
                        <TabPanel value={tabSelectValue} index={2} >
                            {scenario &&
                                <>
                                    <Grid container rowSpacing={4.5} columnSpacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    {conduct && <ConductTranscriptComponent  conduct={conduct} onAnnotate={handleAnnotate} />}
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </>
                            }
                        </TabPanel>
                        <TabPanel value={tabSelectValue} index={3} >
                            {conduct && aar && conduct.portalId && companyId && scenarioId &&
                            <ActionAnalysisComponent conduct={conduct} aar={aar} companyId={companyId}
                                                     portalId={conduct.portalId} editable={true}
                                                     scenarioId={scenarioId}
                                                     onUpdate={handleAARUpdate}
                                                     isPortal={false}
                            />
                            }
                        </TabPanel>
                        <TabPanel value={tabSelectValue} index={4} >
                            {conduct && aar && conduct.portalId && companyId && scenarioId &&
                                <RecommendationAnalysisComponent conduct={conduct} aar={aar} companyId={companyId}
                                                         portalId={conduct.portalId} editable={true}
                                                         scenarioId={scenarioId}
                                                         onUpdate={handleAARUpdate}
                                                         isPortal={false}
                                />
                            }
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
       </MainCard>

           <Dialog open={openAnnotateDialog} fullWidth={true} onClose={closeAnnotateDialog}>
                <DialogTitle>Add Annotation to Tabletop Review</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <TextField
                            name="text"
                            placeholder="Annotate...."
                            fullWidth={true}
                            multiline
                            rows={4}
                            variant="outlined"
                            value={annotateTextValue}
                            onChange={(e) => setAnnotateTextValue(e.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAnnotateDialog}>Cancel</Button>
                    <Button onClick={handleSaveAnnotation} color="primary">Save</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openPublishDialog} fullWidth={true} onClose={closePublishDialog}>
                <DialogTitle>Publish AAR to Portal</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        {companyId && <PortalListSelect companyId={companyId} selectLabel={"Select Publishing Portal"} label={"Select Portal"} onPortalSelect={handlePortalSelect} />}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closePublishDialog}>Cancel</Button>
                    <Button onClick={handlePublishAAR} color="primary">Publish</Button>
                </DialogActions>
            </Dialog>

           <Dialog open={openUnpublishDialog} fullWidth={true} onClose={() => setOpenUnpublishDialog(false)}>
               <DialogTitle>Unpublish AAR </DialogTitle>
               <DialogContent>
                   <Typography variant="body1">
                       You are unpublishing the AAR for this tabletop to the portal. The AAR will NOT BE visible and will become editable.
                   </Typography>

               </DialogContent>
               <DialogActions>
                   <Button variant="contained" onClick={handleUnpublishAAR} color="primary" >Unpublish</Button>
                   <Button variant="contained" onClick={() => setOpenUnpublishDialog(false)}>Cancel</Button>
               </DialogActions>
           </Dialog>
           </>
    );
};
export default DesignerConductViewPage;
