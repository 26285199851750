// material-ui
// import { Theme } from '@mui/material/styles';
import {
    Button,
    Chip, Grid, Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer, TableHead,
    TableRow, Tooltip, Typography
} from '@mui/material';

import { Company} from "../../interfaces/interfaces";
import React, {useMemo} from "react";
import {flexRender, useReactTable, ColumnDef, getCoreRowModel, HeaderGroup} from '@tanstack/react-table';
import ScrollX from "../ScrollX";
import { LabelKeyObject } from 'react-csv/lib/core';
import {Link as RouterLink} from "react-router-dom";
import {ArrowForwardOutlined} from "@mui/icons-material";
import ColorTextBox from "../icon/ColorTextBox";

interface CompanyAssetsProps {
    token: string | null;
    companies: Company[];
}

interface ThreatReactTableProps {
    columns: ColumnDef<Company>[];
    data: Company[];
    title?: string;
}
function TabCompanies({ columns, data, title }: ThreatReactTableProps) {

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel()
    });

    let headers: LabelKeyObject[] = [];
    table.getAllColumns().map((columns) =>
        headers.push({
            label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
            // @ts-ignore
            key: columns.columnDef.accessorKey
        })
    );

    return (
        <>
            <ScrollX>
                <TableContainer component={Paper}>
                    <Table size="medium">
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
                                <TableRow key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => (
                                        <TableCell key={header.id} {...header.column.columnDef.meta}>
                                            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id} {...cell.column.columnDef.meta}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ScrollX>
        </>
    );
}


const CompanyTableView: React.FC<CompanyAssetsProps> = ({  token, companies }) => {
    const data = companies;

    // @ts-ignore
    const columns = useMemo<ColumnDef<Company>[]>(
        () => [
            {
                header: 'Client',
                footer: '',
                accessorKey: 'description',
                cell: (props)=>
                        <Grid container={true} spacing={1}>
                            <Grid item xs={2}>
                                <ColorTextBox size={100} text={props.row.original.name ? props.row.original.name.charAt(0).toUpperCase() : 'C'}/>                            </Grid>
                            <Grid item xs={10}>
                                <Grid container={true} spacing={1}>
                                    <Grid item xs={12}>
                                        <Link  component={RouterLink} to={`/company/viewcompany/${props.row.original.companyId}`}>
                                        <Typography variant="h5">{props.row.original.name}
                                            <Typography variant="caption" color="textSecondary">
                                              &nbsp; ({props.row.original.domain})
                                            </Typography>
                                        </Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container={true} spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" color="textSecondary">
                                                    {props.row.original.description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
            },
            {
                header: '',
                footer: '',
                accessorKey: 'industry',
                cell: (props)=> {
                    return (
                        <Stack direction="column" justifyContent="flex-start" spacing={2}>
                            {props.row.original.industry &&
                                <Chip label={props.row.original.industry} size={"medium"} color={"primary"}
                                      variant="outlined"/>
                            }
                            <Stack direction="column" justifyContent="flex-end" spacing={0}>
                            <Tooltip title={"View Company"}>
                                <Grid container>
                                    <Grid item xs={12}>
                                <Link component={RouterLink}
                                      to={`/company/viewcompany/${props.row.original.companyId}`}>
                                    <Button endIcon={<ArrowForwardOutlined/>} variant={"outlined"} size={"medium"} fullWidth={true}>View</Button>
                                </Link>
                                    </Grid>
                                </Grid>
                            </Tooltip>
                            </Stack>
                        </Stack>

                    );
                }
            }
        ],
        []
    );
    return <TabCompanies {...{ data, columns }} />;
};
export default CompanyTableView;
