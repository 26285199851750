import {Conduct} from "../../interfaces/interfaces";


export function getPlaceholderConduct(): Conduct {
    return {
        location: "",
        agenda: "",
        sendNotification: false,
        isRemote: false,
        timeDuration: 0,
        timeDurationUnit: "",
        conductId: "",
        scenarioId: "",
        orgId: "",
        chatId: "",
        simulationId: "",
        portalId: null,
        companyId: "",
        name: "Placeholder",
        description: "",
        type: "",
        status: "",
        isComplete: false,
        isAARPublished: false,
        sendReminder: false,
        script: [],
        injects: [],
        team: [],
        roles: [],
        notes: "",
        participants: [],
        timeStarted: "",
        failCondition: "",
        elapsedTime: "",
        elapsedTimeInMinutes: 0,
        messages: [],
        objective: "",
        actions: [],
        createDate: "",
        scheduledDate: "",
        userAudit: null,
        files: [],
        contextFiles: [],
        artifacts: [],
        focusArea: "",
        landingPageCode: "",
        isVirtualAssistantOn: false,
        facilitatorMessages: [],
        conductObjectives: [],
        conductMode: "",
        exerciseType: "",
        scenarioStory:null
    };
}