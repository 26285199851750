import {Button, ButtonGroup} from "@mui/material";
import {PlusOutlined} from "@ant-design/icons";
import React from "react";
import {Organization} from "../../interfaces/interfaces";
import AIAssistantIcon from "../icon/AIAssistantIcon";


interface Props {
    handleAnalysisDialog?: () => void;
    handleNewScenarioDialog?: () => void;
    handleAddFromScenarioLibraryDialog?: () => void;
    handleAssistantScenarioAnalysisDialog?: () => void;
    organization?: Organization;
}


const ScenarioRibbonButtonGroup: React.FC<Props> = ({ handleAnalysisDialog, organization,
                                                        handleAddFromScenarioLibraryDialog, handleNewScenarioDialog }) => {


    return (
        <ButtonGroup>
            {/*{handleAnalysisDialog &&*/}
            {/*    <Button variant="outlined" size={"small"} startIcon={<AnalyticsOutlined />} onClick={handleAnalysisDialog}>*/}
            {/*        Analysis*/}
            {/*    </Button>*/}
            {/*}*/}

            { handleAnalysisDialog &&
                <Button variant="outlined" size={"small"} startIcon={<AIAssistantIcon />} onClick={handleAnalysisDialog}>
                   Analysis
                </Button>
            }
            { handleAddFromScenarioLibraryDialog &&
                <Button variant="outlined" size={"small"} startIcon={<PlusOutlined />} onClick={handleAddFromScenarioLibraryDialog}>
                   Library
                </Button>
            }
            <Button variant="outlined" size={"small"} startIcon={<PlusOutlined />} onClick={handleNewScenarioDialog}>
                New
            </Button>
        </ButtonGroup>
    );
};

export default ScenarioRibbonButtonGroup;