import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import QuickLoaderComponent from '../cards/QuickLoaderComponent';
import MainCard from "../MainCard";

interface AARSummaryComponentProps {
    companyId: string;
    scenarioId: string;
    conductId: string;
    token: string;
    summaryLoading: boolean;
}

const AARSummaryComponent: React.FC<AARSummaryComponentProps> = ({ companyId, scenarioId, conductId, token, summaryLoading }) => {
    const [aarSummary, setAARSummary] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(summaryLoading);

    const fetchAARSummary = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/tabletop/aarsummary/' + token + '/' + companyId  + '/' + scenarioId + '/' + conductId);
            const data = await response.text();
            setAARSummary(data);
            setIsLoading(false);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    };

    useEffect(() => {
        fetchAARSummary();
    }, []);

    return (
        <MainCard title="Summary">
        <div style={{ maxHeight: '75em', overflowY: 'auto' }}>
            {aarSummary ?
                <div style={{maxHeight: '75em', overflowY: 'auto'}}>
                    {aarSummary && aarSummary.split('\n').map((line, i) => (
                        <ReactMarkdown key={i} children={line}/>
                    ))}
                </div>
                :
                <div style={{maxHeight: '15em', overflowY: 'auto'}}>
                    {isLoading &&
                        <Grid item xs={12}>
                            <QuickLoaderComponent loadBanner={"Generating AAR Summary....."} />
                        </Grid>
                    }
                </div>
            }
        </div>
        </MainCard>
    );
};

export default AARSummaryComponent;