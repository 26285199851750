// material-ui
import {
    Button, Chip, Divider,
    Grid, Stack,
    Tab,
    Tabs, TextField, Tooltip, Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import React, { useEffect, useState} from "react";
import {
    Organization, UserLinkKey
} from "interfaces/interfaces";

import {a11yProps, TabPanel} from "../../hooks/tabPanelHelpers";
import {BusinessOutlined, ManageAccountsOutlined, TipsAndUpdatesOutlined} from "@mui/icons-material";
import TipsDialog from "../../components/dialog/TipsDialog";
import ServicesPage from "./organization-services";
import ModelPage from "./organization-models";
import SimpleFileUploader from "../../components/SimpleFileUploader";
import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";
import ManageAccessDialog from "../../components/dialog/ManageAccessDialog";
// ==============================|| SAMPLE PAGE ||============================== //

const OrganizationPage = () => {
    // @ts-ignore
    const token = localStorage.getItem('token');
    const [value, setValue] = useState(0);
    const [organization, setOrganization] = useState<Organization>();
    const [openTips, setOpenTips] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // @ts-ignore
        setOrganization((prevModel) => ({
            ...prevModel,
            [name]: value,
        }));
    };

    const [loading, setLoading] = useState(false);

    const handleUpdate = async () => {
        setLoading(true);
        const updateURL = `${process.env.REACT_APP_REST_API_URL}/api/organization/updateorganization/${token}/${organization?.id}`;
        try {
            const response = await fetch(updateURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(organization),
            });
            const data = await response.json();
            setOrganization(data);
        } catch (error) {
            console.error("Error updating model:", error);
        }
        setLoading(false);
    };

    const fetchData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/organization/getorganization/' + token);
            const data = await response.json();
            setOrganization(data);
        } catch (error) {
            console.error(error);
        }

    };
    useEffect(() => {

        fetchData();
    }, []);


    function handleTips() {
        setOpenTips(true);
    }

    function closeTips() {
        setOpenTips(false);
    }

    function handleOrgRefresh() {
        fetchData();
    }

    function handleLogoDelete() {
        const deleteURL = `${process.env.REACT_APP_REST_API_URL}/api/files/organization/deletelogo/${token}`;
        fetch(deleteURL, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                handleOrgRefresh();
            })
            .catch((error) => {
                console.error("Error deleting logo:", error);
            });
    }

    function handleLogoSmallDelete() {
        const deleteURL = `${process.env.REACT_APP_REST_API_URL}/api/files/organization/deletelogosmall/${token}`;
        fetch(deleteURL, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                handleOrgRefresh();
            })
            .catch((error) => {
                console.error("Error deleting logo:", error);
            });
    }

    const [openAccessDialog, setOpenAccessDialog] = useState(false);
    const [userList, setUserList] = useState<UserLinkKey[]>([]);

    function handleAccessChange() {

        try {
            setLoading(true);
            //setLoaderBanner("Updating User Access...");
            fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/access/setdesigneruser/' + token + '/' + organization?.id, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userList),
            }).then(response => {
                if (response.ok) {
                    setOpenAccessDialog(false);
                }
            });
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
        setOpenAccessDialog(false);
    }
    function closeAccessDialog() {
        setOpenAccessDialog(false);
    }
    const handleRemoveUser = (user: UserLinkKey) => {
        setUserList(userList.filter(u => u.userEmail !== user.userEmail));
    };

    async function fetchUsers() {
        try {
            setLoading(true);
            //setLoaderBanner("Loading Users...");
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/access/getdesignerusers/' + token + '/' + organization?.id);
            const data = await response.json();
            setUserList(data);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }
    const handleAddUser = (newUser: UserLinkKey) => setUserList([...userList, newUser]);
    function handleManageAccess() {
        setOpenAccessDialog(true);
        fetchUsers();
    }

return (
        <>
            <MainCard title={
                <Grid container={true} spacing={2}>
                    <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <BusinessOutlined/>
                        <Typography variant="h4">{organization?.name}: Organization Setup</Typography>
                    </Stack>
                    </Grid>
                </Grid>
            }  secondary={
                <Grid container={true} spacing={2}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} justifyItems="flex-end" >
                            <Tooltip title="Manage Access to Opsbook Designer">
                                <Button variant="outlined" onClick={handleManageAccess}><ManageAccountsOutlined /></Button>
                            </Tooltip>
                            <ManageAccessDialog
                                open={openAccessDialog}
                                onClose={closeAccessDialog}
                                userList={userList}
                                onRemoveUser={handleRemoveUser}
                                onAccessChange={handleAccessChange}
                                onAddUser={handleAddUser}
                            />
                        </Stack>
                    </Grid>
                </Grid>

            }
                      content={true} >
                <Grid container={true} spacing={2}>
                    {loading && <QuickLoaderComponent loadBanner={"Updating Organization"}/>}
                    <Grid item xs={12}>
                            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="basic tabs example">
                                <Tab
                                    label="Organization Info"
                                    {...a11yProps(0)}
                                />
                                <Tab label="Services" {...a11yProps(1)} />
                                <Tab label="Models" {...a11yProps(2)} />
                                {/*<Tab label="Team" icon={<Chip label="9" variant="light" size="small" />} iconPosition="end"  {...a11yProps(3)} />*/}
                            </Tabs>
                            <TabPanel value={value} index={0} >
                                {organization &&
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                {organization && organization.isDemo ?
                                                    (
                                                        <Chip label="Free Trial" variant="filled" size="small" color="info" />
                                                    )
                                                    : null  }
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            name="name"
                                                            label="Organization Name"
                                                            variant={"standard"}
                                                            fullWidth
                                                            value={organization.name}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            name="domain"
                                                            label="Organization Domain"
                                                            variant={"standard"}
                                                            fullWidth
                                                            value={organization.domain}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            name="industry"
                                                            label="Industry"
                                                            variant={"standard"}
                                                            fullWidth
                                                            value={organization.industry}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <TextField
                                                            name="description"
                                                            label="Description"
                                                            variant={"standard"}
                                                            fullWidth

                                                            multiline={true}
                                                            value={organization.description}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} >
                                                        {organization.logoLocation &&
                                                            <Stack direction={"row"} spacing={2}>
                                                                <img src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${organization.logoLocation}`} alt="image" style={{ height: '45px'}}/>
                                                                <Button variant={"outlined"} size="small" color={"primary"} onClick={handleLogoDelete}>Delete</Button>
                                                            </Stack>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <SimpleFileUploader apiUrl={process.env.REACT_APP_REST_API_URL+`/api/files/organization/uploadlogo/${token}`} title={"Upload Banner Logo (45x225 recommended)"} onUpload={handleOrgRefresh}/>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        {organization.logoSmallLocation &&
                                                            <Stack direction={"row"} spacing={2}>
                                                                <img src={`${process.env.REACT_APP_REST_API_URL}/api/scenario/image/${organization.logoSmallLocation}`} alt="image" style={{ height: '35px'}}/>
                                                                <Button variant={"outlined"} color={"primary"} size="small"  onClick={handleLogoSmallDelete}>Delete</Button>
                                                            </Stack>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <SimpleFileUploader apiUrl={process.env.REACT_APP_REST_API_URL+`/api/files/organization/uploadlogosmall/${token}`} title={"Upload Mini Logo (35x35 recommended)"} onUpload={handleOrgRefresh}/>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        name="brandHexColor"
                                                        label="Hex Color"
                                                        placeholder={"#000000"}
                                                        variant={"standard"}
                                                        fullWidth
                                                        value={organization.brandHexColor}
                                                        onChange={handleInputChange}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                  <Divider/>
                                            </Grid>
                                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button variant="contained" color="primary" onClick={handleUpdate}>
                                                    Update
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </TabPanel>
                            <TabPanel value={value} index={1} >
                                {organization && <ServicesPage  />}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {organization && <ModelPage />}
                            </TabPanel>
                    </Grid>
                </Grid>
                {organization && organization.isDemo ?
                    (
                        <Stack direction="row" justifyContent="flex-end" spacing={1}> <Button
                            onClick={handleTips}
                            variant="contained"
                            color="success"
                            size="small"
                            startIcon={<TipsAndUpdatesOutlined />}
                        >
                            Tips
                        </Button>
                        </Stack>
                    )
                    : null
                }
            </MainCard>
            <Grid item xs={12} >
                <Typography variant="caption" color={"darkgray"}>{organization?.id}</Typography>
            </Grid>
            <TipsDialog tipsText={["This is the Company page for ACME Corp.", "Here you can view the Threat Profile, create/view Scenarios and Runbooks. When you're getting started, the Threat Profile is a great place to start - build that out ahd then dive into Scenarios which are based off of the Risks identified in the Profile.",
                "Use the 'Setup' tab to edit Company information.", "The Assistant is also a helpful guide in getting started.  Ask it questions about the company, scenarios, risks, etc.."]} open={openTips} handleClose={closeTips}/>

        </>

    );
};
export default OrganizationPage;
