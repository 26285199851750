import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid, Typography
} from "@mui/material";
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import ReactMarkdown from "react-markdown";
import { AssistantScenarioResponse } from "../../interfaces/interfaces";
import {RefreshOutlined} from "@mui/icons-material";

interface Props {
    companyId?: string;
    openScenarioDialog: boolean;
    handleCloseScenarioDialog: () => void;
    handleSaveAnalysis: () => void;
}

const ScenarioAnalysisDialog: React.FC<Props> = ({ openScenarioDialog, handleCloseScenarioDialog, handleSaveAnalysis, companyId }) => {
    const [analysisNotesValue, setAnalysisNotesValue] = useState("");
    const [assistantScenarioAnalysis, setAssistantScenarioAnalysis] = useState<AssistantScenarioResponse[]>([]);
    const [scenarioAnalysisLoading, setScenarioAnalysisLoading] = useState<boolean>(false);

    async function getScenarioAnalysis(companyId: string | undefined) {
        const token = localStorage.getItem('token');
        setAnalysisNotesValue("");
        setScenarioAnalysisLoading(true);
        const response: Response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/company/assistant/generateassistantscenarios/' + token + '/' + companyId);
        const data = await response.json();
        if (data) {
            setAssistantScenarioAnalysis(data);
        }
        setScenarioAnalysisLoading(false);
    }

    useEffect(() => {
        if (openScenarioDialog && assistantScenarioAnalysis.length === 0) {
            getScenarioAnalysis(companyId);
        }
    }, [openScenarioDialog]);

    return (
        <Dialog open={openScenarioDialog} fullWidth={true} onClose={handleCloseScenarioDialog}>
            <DialogTitle>
                Scenario Analysis
                {assistantScenarioAnalysis.length > 0 && (
                    <Button onClick={() => getScenarioAnalysis(companyId)} style={{ marginLeft: '10px' }}>
                        <RefreshOutlined/>
                    </Button>
                )}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {scenarioAnalysisLoading &&
                        <Grid item xs={12}>
                            <QuickLoaderComponent loadBanner={"Performing scenario analysis..."} />
                        </Grid>
                    }
                    {assistantScenarioAnalysis && assistantScenarioAnalysis.map((analysis: AssistantScenarioResponse) => (
                        <>
                            <Grid item xs={12}>
                                <Typography variant={"h3"}>{analysis.name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant={"h5"}>Program:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"body1"}>{analysis.program}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"h5"}>Type:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Typography variant={"body1"}>{analysis.type}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={"h5"}>Description:</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReactMarkdown>{analysis.description}</ReactMarkdown>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant={"h5"}>Story:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={"caption"}>
                                    <ReactMarkdown >{analysis.storyline}</ReactMarkdown>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                        </>
                    ))}
                    <Grid item xs={12}>
                        <ReactMarkdown>{analysisNotesValue}</ReactMarkdown>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={handleCloseScenarioDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScenarioAnalysisDialog;