import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField
} from '@mui/material';
import { UserLinkKey } from "../../interfaces/interfaces";
import UserLinkKeyList from '../../components/shared/UserLinkKeyList';
import { SelectChangeEvent } from "@mui/material";

interface ManageAccessDialogProps {
    open: boolean;
    onClose: () => void;
    userList: UserLinkKey[];
    onRemoveUser: (user: UserLinkKey) => void;
    onAccessChange: () => void;
    onAddUser: (newUser: UserLinkKey) => void;
}

const ManageAccessDialog: React.FC<ManageAccessDialogProps> = ({
    open,
    onClose,
    userList,
    onRemoveUser,
    onAccessChange,
    onAddUser
}) => {
    const [userLinkKey, setUserLinkKey] = useState<UserLinkKey>({
        userId: "",
        userEmail: "",
        username: "",
        userRole: "",
        isMe: false,
        isDesigner: true,
        isPortal: false,
        lastLoggedIn: ""
    });

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleUserLinkKeyChange = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
        const { name, value } = e.target;
        setUserLinkKey(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddUserClick = () => {
        onAddUser(userLinkKey);
    };

    return (
        <Dialog open={open} fullWidth={true} onClose={onClose}>
            <DialogTitle>Manage Access to Opsbook Designer</DialogTitle>
            <DialogContent>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="user management tabs">
                        <Tab label="Existing Users" />
                        <Tab label="Add New User" />
                    </Tabs>
                </Box>
                {tabValue === 0 && (
                    <Box>
                        {userList && userList.length > 0 && <UserLinkKeyList userList={userList} onRemoveUser={onRemoveUser} />}
                    </Box>
                )}
                {tabValue === 1 && (
                    <Box>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                label="Email Address"
                                type="email"
                                variant="outlined"
                                margin="normal"
                                value={userLinkKey.userEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUserLinkKeyChange(e)}
                                name="userEmail"
                            />
                            <TextField
                                id="fullName"
                                label="Full Name"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                value={userLinkKey.username}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUserLinkKeyChange(e)}
                                name="username"
                            />
                            <Select
                                labelId="role-label"
                                id="role"
                                label="Role"
                                variant="outlined"
                                value={userLinkKey.userRole}
                                onChange={handleUserLinkKeyChange}
                                name="userRole"
                            >
                                <MenuItem value={"Designer"}>Designer</MenuItem>
                                <MenuItem value={"DesignerAdmin"}>Designer / Admin</MenuItem>
                            </Select>
                            <Button variant="contained" color="primary" onClick={handleAddUserClick}>
                                Add
                            </Button>
                        </FormControl>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onAccessChange}>
                    Save
                </Button>
                <Button variant={"contained"} onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManageAccessDialog;